import React from "react";
import { ProductGrid } from "components";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";

const Collection = ({ data }) => {
	const collection = data.shopifyCollection;
	const products = data.shopifyCollection.products;
	const arr = products.slice();
	arr.splice(2, 0, { title: "title-box" });

	return (
		<Layout title={collection.title}>
			<ProductGrid
				collection={{
					title: collection.title,
					handle: collection?.handle
				}}
				products={arr}
			/>
		</Layout>
	);
};

export const query = graphql`
	query collectionQuery($id: String) {
		shopifyCollection(id: { eq: $id }) {
			...ShopifyCollectionFields
			products {
				...ShopifyProductFields
				totalInventory
				vendor
				variants {
					...ShopifyVariantFields
					price
					inventoryQuantity
					availableForSale
					sku
				}
			}
		}
	}
`;

export default Collection;
